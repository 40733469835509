<template>
  <div >
    <van-nav-bar fixed
                 placeholder
                 title="Go公司"
                 right-text="分享"
                 @click-right="onClickShare"
                 left-arrow
                 @click-left="onClickLeft" />

    <van-row style="background-color: white; border: 1px dashed #ebedf0">
      <van-col span="6">
        <div style="text-align: center; margin: 5px 0px">
          <img :src="companyDetail.imgUrl"
               alt="Image"
               width="60px"
               height="60px"
               style="
              border: 1px solid #4b96e6a8;
              border-radius: 9px;
              padding: 5px;
            " />
        </div>
      </van-col>
      <van-col span="18">
        <van-cell size="large">
          <template #title>
            <span class="custom-title">{{ companyDetail.name }}</span>
          </template>
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-button v-if="!followStatus"
                        type="info"
                        round
                        size="mini"
                        @click="followCompany">关注</van-button>
            <van-button v-if="followStatus"
                        color="#969799"
                        round
                        size="mini"
                        @click="followCompany">已关注</van-button>
          </template>
          <template #label>
            <div style="margin-top: 10px">
              <van-icon name="location-o" /> {{ companyDetail.address }}
            </div>
          </template>
        </van-cell>
      </van-col>
    </van-row>

    <van-tabs :before-change="beforeChange"
              sticky
              v-model="active">
      <van-tab title="主页" style="background-color: #fff;">
        <van-row type="flex"
                 justify="center">
          <van-col span="22">
            <div v-if="companyDetail.brief != null"
                 class="markdown-body">
              <viewer ref="markdownViewer"
                      :options="viewerOptions"
                      :height="'auto'"
                      :initialValue="companyDetail.brief"></viewer>
            </div>
            <div v-else>
              <van-empty description="介绍整理中" />
            </div>
          </van-col>
        </van-row>
        <van-divider :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0 16px',
          }">
          点评讨论
        </van-divider>
        <van-row type="flex"
                 justify="center">
          <van-col span="22">
            <Valine :options="valineOptions" />
          </van-col>
        </van-row>
      </van-tab>
      <van-tab title="岗位">
        <van-dialog v-model="showSubmitPost"
                    title="扫微信直接联系站长发布">
          <div style="background: #fff"
               align="center">
            <van-image fit="scale-down"
                       width="240px"
                       src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/temp122.png">
            </van-image>
          </div>
        </van-dialog>


          <van-cell icon="bullhorn-o"
                    title="发布岗位得会员">
            <template #right-icon>
              <van-button type="info"
                          round
                          size="mini"
                          @click="showSubmitPost = true">发布</van-button>
            </template>
          </van-cell>


        <van-empty v-if="postList.length === 0"
                   description="暂无岗位" />
        <!-- Go岗位列表 -->
        <van-grid style="padding-top: 5px"
                  :gutter="5"
                  :column-num="1">
          <van-grid-item v-for="(item, index) in postList"
                         :key="index">
            <van-cell @click="handlePostClick(item)">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <van-row>
                  <van-col span="18"
                           style="font-weight: bold">
                    <van-tag plain
                             type="danger"
                             v-if="item.urgentStatus === '1'">急</van-tag>
                    {{ item.post }}
                  </van-col>
                  <van-col span="6"
                           style="text-align: right; color: brown">{{
                    item.salary
                  }}</van-col>
                </van-row>

                <van-row>
                  <van-col span="14">
                    {{ item.company }}
                  </van-col>
                  <!-- <van-col v-if="item.bonus"
                           span="10"
                           style="text-align: right; color: red">推荐入职得：{{ item.bonus }}</van-col> -->
                </van-row>
              </template>

              <template #label>
                <van-row type="flex"
                         gutter="2">
                  <van-col span="3"><van-tag size="medium"
                             type="warning">{{
                      item.city
                    }}</van-tag></van-col>
                  <van-col span="3"><van-tag size="medium"
                             type="primary">{{
                      item.education
                    }}</van-tag></van-col>
                  <!-- <van-col span="3"> <van-tag size="medium"
                           type="primary">{{item.experience}}</van-tag></van-col> -->
                  <!-- style="overflow: hidden; text-align: right;" -->
                  <van-col span="18">
                    <div style="overflow: hidden; height: 18px; text-align: right">
                      {{ item.address }}
                    </div>
                  </van-col>
                </van-row>
              </template>
            </van-cell>
          </van-grid-item>
        </van-grid>
        <van-popup round
                   position="bottom"
                   :style="{ height: '92%' }"
                   v-model="postDetialshow">
          <post-detail get-container="#app"
                       :postDetail="postDetail" />
        </van-popup>
      </van-tab>
      <van-tab title="面经">

          <van-cell icon="bullhorn-o"
                    title="分享面经得会员">
            <template #right-icon>
              <van-button type="info"
                          round
                          size="mini"
                          @click="openInterviewShow()">分享</van-button>
            </template>
          </van-cell>


        <!--分享面经-->

        <van-popup round
                   position="bottom"
                   :style="{ height: '92%' }"
                   v-model="addInterviewShow">
          <van-nav-bar>
            <template #title>
              <span style="font-weight: 600; color: #0af">分享面经</span>
            </template>
          </van-nav-bar>
          <van-form @submit="addInterviewInfo">
            <van-field v-model="interviewInfo.company"
                       readonly
                       name="company"
                       label="公司名称" />

            <van-field v-model="interviewInfo.title"
                       required
                       name="title"
                       label="面经标题"
                       placeholder="请输入面经标题"
                       :rules="[{ required: true, message: '请输入面经标题' }]" />

            <van-field readonly
                       required
                       clickable
                       name="source"
                       :value="interviewInfo.source"
                       label="面试来源"
                       :rules="[{ required: true, message: '请选择面试来源' }]"
                       placeholder="点击选择面试来源"
                       @click="showSourcePicker = true" />
            <van-popup v-model="showSourcePicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="sourceColumns"
                          @confirm="onSourceConfirm"
                          @cancel="showSourcePicker = false" />
            </van-popup>

            <van-field readonly
                       required
                       clickable
                       name="difficulty"
                       :value="interviewInfo.difficulty"
                       label="面试难度"
                       :rules="[{ required: true, message: '请选择面试难度' }]"
                       placeholder="点击选择面试难度"
                       @click="showDifficultyPicker = true" />
            <van-popup v-model="showDifficultyPicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="difficultyColumns"
                          @confirm="onDifficultyConfirm"
                          @cancel="showDifficultyPicker = false" />
            </van-popup>

            <van-field readonly
                       required
                       clickable
                       name="feeling"
                       :value="interviewInfo.feeling"
                       label="面试感受"
                       :rules="[{ required: true, message: '请选择面试感受' }]"
                       placeholder="点击选择面试感受"
                       @click="showFeelingPicker = true" />
            <van-popup v-model="showFeelingPicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="feelingColumns"
                          @confirm="onFeelingConfirm"
                          @cancel="showFeelingPicker = false" />
            </van-popup>

            <van-field readonly
                       required
                       clickable
                       name="grades"
                       :value="interviewInfo.grades"
                       label="职位等级"
                       :rules="[{ required: true, message: '请选择职位等级' }]"
                       placeholder="点击选择职位等级"
                       @click="showGradesPicker = true" />
            <van-popup v-model="showGradesPicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="gradesColumns"
                          @confirm="onGradesConfirm"
                          @cancel="showGradesPicker = false" />
            </van-popup>

            <van-field name="checkboxGroup"
                       required
                       label="面试流程">
              <template #input>
                <!-- 1：、2：、3：、4：、5：、6：、7：、8：9：、10：、11： -->
                <van-checkbox-group v-model="interviewInfo.processes">
                  <van-checkbox name="1"
                                shape="square">电话面试</van-checkbox>
                  <van-checkbox name="2"
                                shape="square">视频面试</van-checkbox>
                  <van-checkbox name="3"
                                shape="square">笔试</van-checkbox>
                  <van-checkbox name="4"
                                shape="square">填写信息表</van-checkbox>
                  <van-checkbox name="5"
                                shape="square">群面</van-checkbox>
                  <van-checkbox name="6"
                                shape="square">部门同事面</van-checkbox>
                  <van-checkbox name="7"
                                shape="square">部门主管面</van-checkbox>
                  <van-checkbox name="8"
                                shape="square">留作业</van-checkbox>
                  <van-checkbox name="9"
                                shape="square">总监/老板面</van-checkbox>
                  <van-checkbox name="10"
                                shape="square">人事HR面</van-checkbox>
                  <van-checkbox name="11"
                                shape="square">其他</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>

            <van-field v-model="interviewInfo.memo"
                       rows="3"
                       required
                       autosize
                       label="面经简介"
                       type="textarea"
                       maxlength="2000"
                       placeholder="简单描述一下你的面试经历。"
                       show-word-limit
                       :rules="[{ required: true, message: '请填写面经简介' }]" />

            <van-field readonly
                       required
                       clickable
                       name="grades"
                       :value="interviewInfo.result"
                       label="面试结果"
                       :rules="[{ required: true, message: '请选择面试结果' }]"
                       placeholder="点击选择面试结果"
                       @click="showResultPicker = true" />
            <van-popup v-model="showResultPicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="resultColumns"
                          @confirm="onResultConfirm"
                          @cancel="showResultPicker = false" />
            </van-popup>

            <div style="margin: 16px">
              <van-button round
                          block
                          type="info"
                          native-type="submit">匿名发布</van-button>
            </div>
          </van-form>
        </van-popup>

        <van-empty v-if="interviewList.length === 0"
                   description="暂无面经" />
        <!-- Go岗位列表 -->
        <van-grid style="padding-top: 5px"
                  :gutter="5"
                  :column-num="1">
          <van-grid-item v-for="(item, index) in interviewList"
                         :key="index">
            <van-cell @click="handleInterviewClick(item)">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <van-row>
                  <van-col span="18"
                           style="font-weight: bold">
                    {{ item.title }}
                  </van-col>
                  <van-col span="6"
                           style="text-align: right">
                    <van-tag size="small"
                             type="warning">{{
                      item.result
                    }}</van-tag>
                  </van-col>
                </van-row>
              </template>

              <template #label>
                <van-row style="margin-top: 5px"
                         type="flex"
                         >
                  <van-col span="7">整体感受：<van-tag plain
                             size="small"
                             type="primary">{{
                      item.feeling
                    }}</van-tag></van-col>

                  <van-col span="8">面试难度：<van-tag plain
                             size="small"
                             type="primary">{{
                      item.difficulty
                    }}</van-tag></van-col>
                         <van-col span="29">
                    面试来源：<van-tag plain
                             size="small"
                             type="primary">{{
                      item.source
                    }}</van-tag></van-col>

                </van-row>
                <van-row style="margin-top: 5px"
                         type="flex"
                         gutter="2">
                  <van-col>
                    面试流程：<van-tag plain
                             type="primary"
                             style="margin-left: 3px"
                             v-for="process in item.processes"
                             :key="process"
                             size="small">{{ process }}
                    </van-tag>
                  </van-col>
                </van-row>
              </template>
            </van-cell>
          </van-grid-item>
        </van-grid>
        <van-popup round
                   position="bottom"
                   :style="{ height: '92%' }"
                   v-model="interviewDetialshow">
          <interview-detail get-container="#app"
                            :interviewDetail="interviewDetails" />
        </van-popup>
      </van-tab>
      <van-tab title="薪酬爆料">
       
          <van-cell icon="bullhorn-o"
                    title="爆料得会员">
            <template #right-icon>
              <van-button type="info"
                          round
                          size="mini"
                          @click="openSalaryShow()">爆料</van-button>
            </template>
          </van-cell>

        <!--薪酬爆料-->
        <van-popup round
                   position="bottom"
                   :style="{ height: '92%' }"
                   v-model="addSalaryInfoShow">
          <van-nav-bar>
            <template #title>
              <span style="font-weight: 600; color: #0af">薪酬爆料</span>
            </template>
          </van-nav-bar>
          <van-form @submit="saveSalaryInfo">
            <van-divider :style="{
                color: '#1989fa',
                borderColor: '#1989fa',
                padding: '0 16px',
              }">基本信息</van-divider>
            <van-field v-model="salaryInfo.company"
                       readonly
                       name="company"
                       label="公司名称" />

            <van-field v-model="salaryInfo.post"
                       required
                       name="company"
                       label="职位信息"
                       placeholder="请输入职位信息"
                       :rules="[{ required: true, message: '请输入职位信息' }]" />

            <van-field name="candidate"
                       required
                       label="求职身份"
                       :rules="[{ required: true, message: '请选择求职身份' }]">
              <template #input>
                <van-radio-group v-model="salaryInfo.candidate"
                                 direction="horizontal">
                  <van-radio name="0">校招</van-radio>
                  <van-radio name="1">社招</van-radio>
                </van-radio-group>
              </template>
            </van-field>

            <van-field readonly
                       required
                       clickable
                       name="workYear"
                       :value="salaryInfo.workYear"
                       label="职位经验"
                       :rules="[{ required: true, message: '请选择职位经验' }]"
                       placeholder="点击选择职位经验"
                       @click="showWorkYearPicker = true" />
            <van-popup v-model="showWorkYearPicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="workYearColumns"
                          @confirm="onWorkYearConfirm"
                          @cancel="showWorkYearPicker = false" />
            </van-popup>

            <van-field readonly
                       required
                       clickable
                       name="workStart"
                       :value="salaryInfo.workStart"
                       label="入职时间"
                       placeholder="选择日期"
                       @click="showWorkStartTimePicker = true"
                       :rules="[{ required: true, message: '请填写入职时间' }]" />
            <van-popup v-model="showWorkStartTimePicker"
                       position="bottom">
              <van-datetime-picker title="入职时间"
                                   type="year-month"
                                   :formatter="formatter"
                                   :min-date="minWorkDate"
                                   :max-date="maxDate"
                                   @confirm="onWorkStartTimeConfirm"
                                   @cancel="showWorkStartTimePicker = false" />
            </van-popup>
            <van-field readonly
                       clickable
                       name="workEnd"
                       :value="salaryInfo.workEnd"
                       label="离职时间"
                       placeholder="选择日期（在职可不填）"
                       @click="showWorkEndTimePicker = true" />
            <van-popup v-model="showWorkEndTimePicker"
                       position="bottom">
              <van-datetime-picker title="离职时间"
                                   type="year-month"
                                   :formatter="formatter"
                                   :min-date="minWorkDate"
                                   :max-date="maxDate"
                                   @confirm="onWorkEndTimeConfirm"
                                   @cancel="showWorkEndTimePicker = false" />
            </van-popup>

            <van-divider :style="{
                color: '#1989fa',
                borderColor: '#1989fa',
                padding: '0 16px',
              }">工资/奖金/股票期权</van-divider>

            <van-field v-model="salaryInfo.salary"
                       required
                       type="digit"
                       name="salary"
                       label="工资/月"
                       placeholder="请输入工资" />

            <van-field v-model="salaryInfo.annualBonus"
                       type="digit"
                       name="annualBonus"
                       label="年度奖金"
                       placeholder="请输入奖金" />

            <van-field v-model="salaryInfo.stockOption"
                       type="digit"
                       name="stockOption"
                       label="股票期权"
                       placeholder="请输入股票期权每年收益" />

            <van-field label="合计年薪"
                       :value="totalSalary"
                       readonly />

            <div style="margin: 16px">
              <van-button round
                          block
                          type="info"
                          native-type="submit">保存</van-button>
            </div>
          </van-form>
        </van-popup>

        <van-empty v-if="salaryList.length === 0"
                   description="暂无爆料" />
        <!-- Go岗位列表 -->
        <van-grid style="padding-top: 5px"
                  :gutter="5"
                  :column-num="1">
          <van-grid-item v-for="(item, index) in salaryList"
                         :key="index">
            <van-cell>
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <van-row>
                  <van-col style="font-weight: bold"><van-icon name="manager-o" />
                    {{ item.post }}
                  </van-col>
                </van-row>
              </template>

              <template #label>
                <van-row style="margin-top: 5px"
                         type="flex"
                         justify="space-between">
                  <van-col span="24">职位经验 ：<van-tag plain
                             size="small"
                             type="primary">{{ item.workYear }}</van-tag></van-col>

                  <!-- <van-col span="17">在职时间：<van-tag plain
                             size="small"
                             type="primary">{{ item.workStart }}-{{ item.workEnd }}</van-tag></van-col> -->
                </van-row>
                <van-row style="margin-top: 5px"
                         type="flex"
                         justify="space-between">


                  <van-col span="24">在职时间：<van-tag plain
                             size="small"
                             type="primary">{{ item.workStart }}</van-tag> - <van-tag plain
                             size="small"
                             type="primary">{{ item.workEnd }}</van-tag></van-col>
                </van-row>
                <van-row style="margin-top: 5px"
                         type="flex"
                         justify="space-between">
                  <van-col span="10">工资 / 月 ：<van-tag plain
                             size="small"
                             type="primary">￥{{
                        item.salary === undefined ? "-" : item.salary
                      }}/月</van-tag></van-col>

                  <van-col span="14">年度奖金：<van-tag plain
                             size="small"
                             type="primary">￥{{
                        item.annualBonus === undefined ? "-" : item.annualBonus
                      }}/年</van-tag></van-col>
                </van-row>
                <van-row style="margin-top: 5px"
                         type="flex"
                         justify="space-between">
                  <van-col span="10">股票期权 ：<van-tag plain
                             size="small"
                             type="primary">￥{{
                        item.stockOption === undefined ? "-" : item.stockOption
                      }}/年</van-tag></van-col>

                  <van-col span="14">合计年薪：<van-tag plain
                             size="small"
                             type="primary">￥{{ item.total }}/年</van-tag></van-col>
                </van-row>
              </template>
            </van-cell>
          </van-grid-item>
        </van-grid>
      </van-tab>
      <van-tab title="内推官">

          <van-cell icon="bullhorn-o"
                    title="认证内推官得会员">
            <template #right-icon>
              <van-button type="info"
                          round
                          size="mini"
                          @click="openPushShow()">认证</van-button>
            </template>
          </van-cell>

        <!--联系内推官-->
        <van-dialog v-model="contactPusherShow"
                    title="为保护内推官隐私，请通过站长进行转投">
          <div style="background: #fff"
               align="center">
            <van-image fit="scale-down"
                       width="240px"
                       src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/temp122.png">
            </van-image>
          </div>
        </van-dialog>

        <!--认证内推官-->
        <van-popup round
                   position="bottom"
                   :style="{ height: '92%' }"
                   v-model="addPushShow">
          <van-nav-bar>
            <template #title>
              <span style="font-weight: 600; color: #0af">认证内推官</span>
            </template>
          </van-nav-bar>
          <br />

          <van-form @submit="savePushInfo">
            <van-field v-model="companyDetail.name"
                       readonly
                       name="company"
                       label="内推公司" />

            <van-field readonly
                       required
                       clickable
                       name="picker"
                       :value="pushInfo.position"
                       label="渠道类型"
                       :rules="[{ required: true, message: '请选择渠道类型' }]"
                       placeholder="点击选择渠道类型"
                       @click="showPositionPicker = true" />
            <van-popup v-model="showPositionPicker"
                       position="bottom">
              <van-picker show-toolbar
                          :columns="positionColumns"
                          @confirm="onPositionConfirm"
                          @cancel="showPositionPicker = false" />
            </van-popup>

            <van-field name="email"
                       required
                       v-model="pushInfo.email"
                       label="合作邮箱"
                       placeholder="请填写邮箱,用于后期简历接收。"
                       :rules="[
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: '邮箱格式不正确',
                },
              ]" />
            <van-field v-model="pushInfo.brief"
                       rows="2"
                       required
                       autosize
                       label="帮推签名"
                       type="textarea"
                       maxlength="200"
                       placeholder="请填写你的帮推签名吧。"
                       show-word-limit
                       :rules="[{ required: true, message: '请填写帮推签名' }]" />
            <div style="margin: 16px">
              <van-button round
                          block
                          type="info"
                          native-type="submit">保存</van-button>
            </div>
          </van-form>
        </van-popup>

        <van-empty v-if="pushList.length === 0"
                   description="暂无内推官" />
        <!-- 内推官列表 -->
        <van-grid style="padding-top: 5px"
                  :gutter="1"
                  :column-num="1">
          <van-grid-item v-for="(item, index) in pushList"
                         :key="index">
            <van-cell @click="contactPusherShow = true">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <van-row>
                  <van-col span="18"
                           style="font-weight: bold"> Gopher-{{ item.pushUid }} <van-icon name="chat-o"
                              dot
                              color="#1989fa" />
                  </van-col>
                  <van-col span="6"
                           style="text-align: right">
                    <van-tag size="small"
                             type="warning">
                      {{ item.position }}</van-tag>
                  </van-col>
                </van-row>
              </template>

              <template #label>
                <van-icon name="envelop-o" /> {{ item.brief }}
              </template>
            </van-cell>
          </van-grid-item>
        </van-grid>
      </van-tab>
      <van-tab title="点评" style="background-color: #fff;">
        <van-divider :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0 16px',
          }">
          点评讨论
        </van-divider>
        <van-row type="flex"
                 justify="center">
          <van-col span="22">
            <Valine :options="valineOptions" />
          </van-col>
        </van-row>
      </van-tab>
    </van-tabs>

    <router-view />

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />
  </div>
</template>

<script>
import {
  Checkbox, CheckboxGroup,
  DatetimePicker,
  RadioGroup,
  Radio,
  Toast,
  Picker,
  CellGroup,
  Popup,
  Tag,
  Divider,
  Cell,
  Empty,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  ImagePreview,
  Dialog,
  ShareSheet,
  Form,
  Field,
  Icon,
  Button,
  NoticeBar,
  Image as VanImage,
  Col,
  Row,
  NavBar,
  Grid,
  GridItem,
} from "vant";

import PostDetail from "../../components/PostDetail.vue";
import InterviewDetail from "../../components/InterviewDetail.vue";
import Valine from "../Valine.vue";
const axios = require("axios");

import "prismjs/themes/prism.css";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import Prism from "prismjs";
import "prismjs/components/prism-go.js";
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import "../css/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";

export default {
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [DatetimePicker.name]: DatetimePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Toast.name]: Toast,
    [Picker.name]: Picker,
    [CellGroup.name]: CellGroup,
    InterviewDetail,
    [Popup.name]: Popup,
    PostDetail,
    [Tag.name]: Tag,
    viewer: Viewer,
    Valine,
    [Cell.name]: Cell,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [ImagePreview.Component.name]: ImagePreview.Component,

    [Dialog.Component.name]: Dialog.Component,
    [ShareSheet.name]: ShareSheet,
    [Form.name]: Form,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,

    [Col.name]: Col,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
  },
  data () {
    return {
      loginStatus: false,
      followStatus: false,
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
      },
      //发布岗位弹窗
      showSubmitPost: false,
      //岗位列表
      postList: [],
      postDetialshow: false,
      postDetail: {
        address: "雁塔区华为西安研究所锦业路127号",
        bonus: "2000",
        company: "华为",
        companyCity: "西安",
        createTime: "2022-01-08",
        education: "本科",
        experience: "校招",
        id: 31,
        memo: "",
        post: "开发",
        postCity: "重庆",
        pushName: "MR.Tan",
        salary: "16K*14薪",
        urgentStatus: "0",
        detail: "",
      },
      //面经
      addInterviewShow: false,
      interviewList: {},
      interviewDetialshow: false,
      interviewDetails: {
        company: "华为",
        createTime: "2022-01-08",
        details: "",
        difficulty: "一般",
        feeling: "一般",
        grades: "高级",
        id: 7,
        memo: "有英语面试。。技术面。。HR面",
        processes: ["电话面试", "人事HR面"],
        pushName: "MR.Tan2",
        result: "确定通过",
        source: "社会招聘",
        title: "汇丰3面",
      },
      interviewInfo: {},
      sourceColumns: [
        "校园招聘",
        "内部推荐",
        "猎头推荐",
        "社会招聘",
        "BOSS直聘",
        "本站内推",
        "其他网络平台",
      ],
      showSourcePicker: false,
      difficultyColumns: ["很容易", "一般", "有难度", "困难", "巨难"],
      showDifficultyPicker: false,
      feelingColumns: ["不好", "一般", "很好"],
      showFeelingPicker: false,
      gradesColumns: ["初级", "中级", "高级"],
      showGradesPicker: false,
      resultColumns: ["确定通过", "感觉靠谱", "感觉没戏", "未通过"],
      showResultPicker: false,
      //薪酬爆料
      addSalaryInfoShow: false,
      salaryInfo: {
        post: "",
        candidate: 0,
        city: "",
        companyId: "",
        company: "",
        workYear: "",
        workStart: "",
        workEnd: "",
        // salary: '',
        // annualBonus: '',
        // stockOption: '',
      },
      salaryList: [],
      showWorkStartTimePicker: false,
      showWorkEndTimePicker: false,
      minWorkDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      showWorkYearPicker: false,
      workYearColumns: [
        "1年以下",
        "1年",
        "2年",
        "3年",
        "4年",
        "5年",
        "6年",
        "7年",
        "8年",
        "9年",
        "10年",
        "10年以上",
      ],
      //认证内推官
      contactPusherShow: false,
      addPushShow: false,
      pushList: [],
      showPositionPicker: false,
      positionColumns: ["内部员工", "招聘专员", "猎头"],
      pushInfo: {
        companyId: 1,
        position: "",
        brief: "",
        email: "",
        city: "",
      },
      active: 0,
      showShare: false,
      shareOptions: [
        { name: "微信", icon: "wechat" },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        {
          name: "分享海报",
          icon: "https://img01.yzcdn.cn/vant/custom-icon-light.png",
        },
        // { name: '二维码', icon: 'qrcode' },
      ],
      valineOptions: {
        appId: "kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz",
        appKey: "nscorEe7TaRyn1DBFNicKsWH",
        placeholder:
          "欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。",
        path: window.location.pathname,
      },
      id: 1,
      companyDetail: {
        address: "海淀区金隅智造工场N6 海淀区融科资讯中心A座3层",
        brief: "",
        city: "北京",
        id: 256,
        imgUrl:
          "https://image-1302243118.cos.ap-beijing.myqcloud.com/img/MEGVII.jpg",
        name: "旷视MEGVII",
        pushId: 299,
        ranking: 0,
        sortId: 0,
        status: 1,
      },
    };
  },
  computed: {
    totalSalary: function () {
      return (
        parseInt(this.salaryInfo.salary == null ? 0 : this.salaryInfo.salary) *
        12 +
        parseInt(
          this.salaryInfo.stockOption == null ? 0 : this.salaryInfo.stockOption
        ) +
        parseInt(
          this.salaryInfo.annualBonus == null ? 0 : this.salaryInfo.annualBonus
        )
      );
    },
  },
  created () {
    this.id = this.$route.query.id;
    this.valineOptions.path = "/#/company&id=" + this.id;
    //设置邀请码
    if (this.$route.query.code !== undefined) {
      window.localStorage.setItem('code', this.$route.query.code)
    }
    this.initData();
  },
  methods: {
    initData () {
      axios
        .get("https://api.golangroadmap.com/gopherclub/m/company/detail?id=" + this.id)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.companyDetail = response.data.data.companyDetail;
            this.$refs.markdownViewer.invoke(
              "setMarkdown",
              this.companyDetail.brief
            );
            this.postList = response.data.data.postList;
            this.interviewList = response.data.data.interviewList;
            this.salaryList = response.data.data.salaryList;
            this.pushList = response.data.data.pushList;
            this.loginStatus = response.data.data.loginStatus;
            this.followStatus = response.data.data.followStatus;
          } else {
            console.log(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    beforeChange (index) {
      console.log(index)
      if (!this.loginStatus) {

        Dialog.confirm({
          message: '您还未登录，请先登录后查看更多公司信息！',
        })
          .then(() => {
            this.$router.push("login");
          })
          .catch(() => {
            // on cancel
            return false;
          });

        return false;
      } else {
        return true;
      }
    },
    openPushShow () {
      this.initPushInfo();

      this.addPushShow = true;
    },
    initPushInfo () {
      this.pushInfo.city = this.companyDetail.city;
      this.pushInfo.companyId = this.companyDetail.id;
      this.pushInfo.position = "";
      this.pushInfo.brief = "";
      this.pushInfo.email = "";
    },
    //认证内推专员保存
    savePushInfo () {
      axios
        .post("https://api.golangroadmap.com/gopherclub/addResumePush", this.pushInfo)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("认证内推官申请完成!");
            this.pushList.push(response.data.data);
            this.addPushShow = false;
            this.initPushInfo();
          } else {
            Toast.fail(response.data.msg);
            this.addPushShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //面经
    openInterviewShow () {
      this.initInterviewInfo();
      this.addInterviewShow = true;
    },
    addInterviewInfo () {
      axios
        .post("https://api.golangroadmap.com/gopherclub/m/addInterview", this.interviewInfo)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("发布面经成功!");
            this.interviewList.push(response.data.data)
            this.initInterviewInfo();
            this.addInterviewShow = false;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initInterviewInfo () {
      this.interviewInfo.city = this.companyDetail.city;
      this.interviewInfo.companyId = this.companyDetail.id;
      this.interviewInfo.company = this.companyDetail.name;
      this.interviewInfo.difficulty = "";
      this.interviewInfo.feeling = "";
      this.interviewInfo.grades = "";
      this.interviewInfo.memo = "";
      // this.interviewInfo.processes =[];
      this.interviewInfo.result = "";
      this.interviewInfo.source = "";
      this.interviewInfo.title = "";

    },
    onSourceConfirm (value) {
      this.interviewInfo.source = value;
      this.showSourcePicker = false;
    },
    onDifficultyConfirm (value) {
      this.interviewInfo.difficulty = value;
      this.showDifficultyPicker = false;
    },
    onFeelingConfirm (value) {
      this.interviewInfo.feeling = value;
      this.showFeelingPicker = false;
    },
    onGradesConfirm (value) {
      this.interviewInfo.grades = value;
      this.showGradesPicker = false;
    },
    onResultConfirm (value) {
      this.interviewInfo.result = value;
      this.showResultPicker = false;
    },
    //薪酬爆料
    openSalaryShow () {
      this.initSalaryInfo();

      this.addSalaryInfoShow = true;
    },
    initSalaryInfo () {
      this.salaryInfo.city = this.companyDetail.city;
      this.salaryInfo.companyId = this.companyDetail.id;
      this.salaryInfo.company = this.companyDetail.name;
      this.salaryInfo.workYear = "";
      this.salaryInfo.workStart = "";
      this.salaryInfo.workEnd = "";
      this.salaryInfo.candidate = "";
    },
    saveSalaryInfo () {
      axios
        .post(
          "https://api.golangroadmap.com/gopherclub/addCompanySalary",
          this.salaryInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("薪资爆料成功!");
            this.salaryList.push(response.data.data);
            this.initSalaryInfo();
            this.addSalaryInfoShow = false;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    formatDate (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      // const day = String(date.getDate()).padStart(2, "0");

      return `${year}年${month}月`;
    },

    onWorkStartTimeConfirm (date) {
      console.log(date);
      this.salaryInfo.workStart = this.formatDate(date);
      this.showWorkStartTimePicker = false;
    },
    onWorkEndTimeConfirm (date) {
      this.salaryInfo.workEnd = this.formatDate(date);
      this.showWorkEndTimePicker = false;
    },
    onWorkYearConfirm (value) {
      this.salaryInfo.workYear = value;
      this.showWorkYearPicker = false;
    },
    onPositionConfirm (value) {
      this.pushInfo.position = value;
      this.showPositionPicker = false;
    },
    //查看岗位详情
    handlePostClick (item) {
      Object.assign(this.postDetail, item);
      this.postDetialshow = true;
    },
    handleInterviewClick (item) {
      Object.assign(this.interviewDetails, item);
      this.interviewDetialshow = true;
    },
    //打开分享
    onClickShare () {
      this.showShare = true;
    },
    onClickLeft () {
      this.$router.push({ path: "/home", query: { tab: "7" } });
    },
    //点击头像进入服务页
    txClick () {
      this.$router.push("user");
    },
    //关注公司
    followCompany () {

      if (!this.loginStatus) {
        Dialog.confirm({
          message: '您还未登录，请先登录后再关注！',
        })
          .then(() => {
            this.$router.push("login");
          })
          .catch(() => {
            // on cancel
          });
      } else {
        axios
          .get("https://api.golangroadmap.com/gopherclub/m/company/follow?id=" + this.id)
          .then((response) => {
            if (response.status == "200" && response.data.code == "100") {
              if (this.followStatus) {
                Toast.success("取消关注!");
                this.followStatus = false;
              } else {
                Toast.success("关注成功!");
                this.followStatus = true;
              }
            } else {
              Toast.fail(response.data.msg);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    //打开分享
    onClickRight () {
      this.showShare = true;
    },
    //分享
    onSelect (shareOption) {
      if (shareOption.name == "分享海报") {
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/company_' + this.companyDetail.id + '.jpg'

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };
      } else {
        Dialog({ message: '公司信息链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = "分享一个Go公司给你：https://m.golangroadmap.com/#/company?id=" + this.companyDetail.id + "&code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
  },
};
</script>

<style>
.van-tabs__line {
  background-color: #39a9ed;
}

.van-grid-item__content {
  padding: 0;
}

.grid-item {
  display: flex;
  flex-direction: column;
}

.grid-img {
  align-items: center;
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
}

.grid-title {
  padding: 0 7px;
  font-size: 14px;

  height: 40px;
}
</style>
