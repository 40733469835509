<template>
  <div style="background-color: white;">
    <van-sticky :offset-top="50">
      <van-cell >
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <van-row>
                  <van-col span="18" style="font-weight: bold">
                    {{ interviewDetail.title }}
                  </van-col>
                  <van-col span="6" style="text-align: right">
                    <van-tag  size="small" type="warning">{{
                      interviewDetail.result
                    }}</van-tag>
                  </van-col>
                </van-row>
              </template>

              <template #label>
                <van-row style="margin-top:5px;" type="flex" justify="space-between">
                  
                  <van-col span="7"
                    >整体感受：<van-tag plain size="small" type="primary">{{
                      interviewDetail.feeling
                    }}</van-tag></van-col
                  >
                  
                  <van-col span="8"
                    >面试难度：<van-tag plain size="small" type="primary">{{
                      interviewDetail.difficulty
                    }}</van-tag></van-col
                  >
                 
                  <van-col span="9"
                    > 面试来源：<van-tag plain size="small" type="primary">{{
                      interviewDetail.source
                    }}</van-tag></van-col
                  >
                </van-row>
                <van-row style="margin-top:5px;" type="flex" gutter="2">                 
                  <van-col>
                    面试流程：<van-tag plain type="primary" style="margin-left: 3px" v-for="process in interviewDetail.processes" :key="process" size="small">{{ process }}
                    </van-tag>
                  </van-col>
                </van-row>
              </template>
            </van-cell>
    </van-sticky>

    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="interviewDetail.details != null"
             class="markdown-body">
          <viewer ref="markdownViewer"
                  :options="viewerOptions"
                  :height="'auto'"
                  :initialValue="interviewDetail.details"></viewer>
        </div>
        <div v-else>
          <van-empty description="面经整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      参与讨论
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Dialog, ShareSheet, Sticky, Divider, Empty, Cell, Tag, Col, Row, Icon } from 'vant';
import Valine from './Valine.vue';

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
// Step 1. Import prismjs
import Prism from 'prismjs';
// Step 2. Import language files of prismjs that you need(后面也可以加java)
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import './toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

export default {
  props: {
    interviewDetail: {
      type: Object,
      required: true
    }
  },
  watch: {
    interviewDetail: {
      handler: function (newVal, oldVal) {
        console.log('interviewDetail changed from', oldVal, 'to', newVal);
        this.valineOptions.path = '/interview/' + this.interviewDetail.id + '.html'
        this.$nextTick(() => {
          this.$refs.markdownViewer.invoke('setMarkdown', this.interviewDetail.details);
        })
      },
      deep: true
    }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    viewer: Viewer,
    [Sticky.name]: Sticky,
    [Divider.name]: Divider,

    Valine,
    [Empty.name]: Empty,

    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,

  },

  data () {
    return {
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },

      valineOptions: {
        appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
        appKey: 'nscorEe7TaRyn1DBFNicKsWH',
        placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
        path: window.location.pathname,
      },
    };
  },
  //初始化评论
  mounted () {
    this.valineOptions.path = '/interview/' + this.interviewDetail.id + '.html'
  },
  methods: {
    //分享 
    onSelect (shareOption) {

      if (shareOption.name == '分享海报') {
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/interview_' + this.interviewDetail.id + '.jpg'

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };

      } else {
        Dialog({ message: '本文链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = this.interviewDetail.title + ": https://m.golangroadmap.com/#/shareInterview?id=" + this.interviewDetail.id + "&code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
    //打开分享
    onClickShare () {
      this.showShare = true
    },
  }
};
</script>

<style lang="less">
.interview-detail-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.31;
}

/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
